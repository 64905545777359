.project-info{
    margin-top: 60px;
    
    .agent{
        font-size: 13px;
        .image{
            margin-bottom: 15px;
        }
        ul{
            padding-left: 20px;
        }
        margin-bottom: 20px;
    }
    
    @media(max-width: 767px){
        margin-top: 20px;
    }
    .gmap{
        iframe{
            width: 100%!important;
            height: 324px!important;
        }
    }
    
    .right-image{
        height: 324px!important;
    }
    
    .orange-button{
        padding: 10px 15px;
        color: #fff;
        background: #F99B1D;
        text-transform: uppercase;
        min-width: 250px;
        display: inline-block;
        margin-bottom: 15px;
        line-height: 1;
            font-weight: bold;
    font-family: 'HelveticaNC';
    
    &:hover, &:focus{
        text-decoration: none;
        background: darken(#F99B1D,10%);
    }
    }
    .more{
        font-family: 'HelveticaNC';
        font-weight: bold; 
        font-size: 16px;
        text-transform: uppercase;
        position: relative;
        padding-right: 170px;
        @media(max-width: 767px){
         padding-right: 0;
        }
        color: #626262;
        margin-top: 10px;
        a.download-pdf{
            font-weight: normal; 
            position: absolute; 
            top: 0; 
            right: 0; 
            vertical-align: top;
            display: inline-block;
            
            
            @media(max-width: 767px){
                position: relative;
                margin: 15px 0;
                margin-top: 30px;
                display: block; 
            }
            
            
            .download-text{
                float: left; 
                color: #626262;
                @media(max-width: 767px){
                    float: none; 
                    display: block; 
                }
                
            }
            
            img{
                float: right; 
                margin-left: 5px;
                @media(max-width: 767px){
                    float: right;
                    display: inline-block;
                    top: 0;
                    position: absolute;
                    left: 78px;
                }
                
            }
        }
    }
    .details{
        list-style: none; 
        @media(max-width: 767px){
         margin: 20px 0; 
         padding-left: 0;
        }
        li{
            a{
                background: #ebebeb;
                display: block; 
                padding: 6px 15px 7px;
                padding-right: 67px;
                position: relative;
                margin-bottom: 10px;
                
                span{
                    display: inline-block; 
                    position: absolute;
                    right: 0; 
                    top:0; 
                    border-left: 10px solid #fff;
                }
                
                font-size: 16px;
                font-family: 'HelveticaNC';
                color: #626262;
                line-height: 1;
                .title{
                    font-weight: bold;
                    line-height: 18px;
                    
                }
                
                &:hover{
                    text-decoration: none; 
                    background: #dbdbdb;
                }
                
            }
        }
    }
}