#header{
    
    .navbar{
        @media(max-width: 991px){
            text-align: center;
            margin-bottom: 0;
        }
        @media(max-width: 767px){
            margin: 0 -20px;
            border: 0px;
        }
        
        
        .navbar-header{
            background: #fff;
        }
        
    }
    
    background: #fff;
    padding-top: 20px;
    @media(max-width: 767px){
        padding-top: 0px;
        
        .navbar-toggle .icon-bar{
            background: #666;
            height: 3px;
        }
    }
    #logo{
        text-align: center;
    }
    
    #main-menu{
        text-align: right;
        margin: 20px;
        margin-bottom: 0;
        margin-top: 60px;
        
        
        @media(min-width: 768px){
             display: block!important; 
        }
        
        @media(max-width: 991px){
            text-align: center;
            margin: 10px;
        }
        
        @media(max-width: 767px){
            
            display: none; 
            
            position: absolute; 
            width: 100%;
            top: 50px;
            left: 0;
            background: #fff; /* Old browsers */
            
            z-index:110;
            text-align: center;
            margin: 0;
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 5px solid #6f6f6f;
        }
        
        > li{
            display: inline-block;
            position: relative;
            cursor: pointer;
            @media(max-width: 767px){
                display: block;
            }
            
            
            > a{
                color: #6f6f6f;
                font-size: 15px;
                text-transform: uppercase;
                margin: 0 0px 0 15px;
                @media(max-width: 1199px){
                    
                    font-size: 14px;
                   
                   
                }
                @media(max-width: 767px){
                    padding: 10px;
                    display: block;
                    font-size: 19px;
                    margin: 0 0px 0 15px;
                   
                }
                font-weight: bold;
                font-family: 'HelveticaNC';
                padding-bottom: 13px;
                background-image: none;
                background-position: center bottom;
                background-repeat: no-repeat;
                background-size: 20px auto;
                
                
                
                
            }
            
            
            
             
           
             
             .submenu{
                 display: none; 
                 padding-top: 17px;
                 position: absolute; 
                 @media(max-width: 767px){
                     position: relative; 
                 }
                 width: 100%;
                 
                 .items{
                    padding: 10px 15px 12px;
                    position: absolute; 
                    
                    z-index: 100;
                    left: 10px;
                    width: 265px;
                    text-align: left; 
                    
                    @media(max-width: 767px){
                        position: relative; 
                        left: 0px;
                        width: 100%;
                        text-align: center; 
                    }
                    .bg{
                        background: #55618f; /* Old browsers */
                       background: -moz-linear-gradient(45deg, #55618f 0%, #51ddc8 100%); /* FF3.6-15 */
                       background: -webkit-linear-gradient(45deg, #55618f 0%,#51ddc8 100%); /* Chrome10-25,Safari5.1-6 */
                       background: linear-gradient(45deg, #55618f 0%,#51ddc8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                       filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55618f', endColorstr='#51ddc8',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                       filter: alpha(opacity=80);
                       /* IE */
                       -moz-opacity: 0.8;
                       /* Mozilla */
                       opacity: 0.8;
                       /* CSS3 */
                       position: absolute;
                       top: 0;
                       left: 0;
                       height: 100%;
                       width: 100%;
                       z-index: 1;
                       @media(max-width: 767px){
                           
                           background: #55618f!important;
                           
                       }
                       
                    }
                    a{
                            color: #fff;
                            font-size: 15px;
                            display: block;
                            padding: 3px 0;
                            position: relative; 
                            z-index: 3;
                            &:hover{
                                color: #F99B1D;
                                text-decoration: none;
                            }
                            @media(max-width: 767px){
                                
                                font-size: 18px;
                            }
                        }
                 }
                 
               
                
             
                
                
             }
             
             &:hover{
                 .submenu{
                     display: block; 
                 }
                > a{
                    text-decoration: none;
                    background-image: url('/img/arrow.png');
                }
                
             }
             
        }
    }
    
    .social{
        margin: 20px;
        margin-top: 23px;
        text-align: right;
        @media(max-width: 991px){
            text-align: center;
        }
        @media(max-width: 991px){
            margin: 5px 0 0 0 ;      
        }
        @media(max-width: 767px){
            margin-top: 0;
            position: absolute; 
            top: 3px;
            margin: 10px;
        }
        li{
            display: inline-block;
            a{
                color: #6f6f6f;
                font-size: 15px;
                display: block; 
                margin-left: 5px;
                @media(max-width: 767px){
                     font-size: 18px;
                     margin-left: 10px;
                }
                
            }
            &:hover{
                a{
                    color: #cc713c;
                    text-decoration: none;
                }
             }
        }
    }
    
}