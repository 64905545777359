.scr{
    
    &.purple{
        h1{
            color: #bc5aa2;
        }
        .ribbon{
            height: 25px;
            background: #548ab6; /* Old browsers */
            background: -moz-linear-gradient(20deg, #8b568a 0%, #8b568a 22%, #8b568a 22%, #bc5aa2 22%, #bc5aa2 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(20deg, #8b568a 0%,#8b568a 22%,#8b568a 22%,#bc5aa2 22%,#bc5aa2 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(20deg, #8b568a 0%,#8b568a 22%,#8b568a 22%,#bc5aa2 22%,#bc5aa2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b568a', endColorstr='#bc5aa2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
    }
    
    &.orange{
        h1{
            color: #F99B1D; 
        }
        
        .ribbon{
            height: 25px;
            background: #548ab6; /* Old browsers */
            background: -moz-linear-gradient(20deg, #F09D61 0%, #F09D61 22%, #F09D61 22%, #F3B466 22%, #F3B466 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(20deg, #F09D61 0%,#F09D61 22%,#F09D61 22%,#F3B466 22%,#F3B466 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(20deg, #F09D61 0%,#F09D61 22%,#F09D61 22%,#F3B466 22%,#F3B466 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F09D61', endColorstr='#F3B466',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
    }
    
    &.green{
        h1{
            color: #5B9B47;
        }
        .ribbon{
            height: 25px;
            background: #548ab6; /* Old browsers */
            background: -moz-linear-gradient(20deg, #68805E 0%, #68805E 22%, #68805E 22%, #79A869 22%, #79A869 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(20deg, #68805E 0%,#68805E 22%,#68805E 22%,#79A869 22%,#79A869 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(20deg, #68805E 0%,#68805E 22%,#68805E 22%,#79A869 22%,#79A869 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#68805E', endColorstr='#79A869',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
    }
    
    &.gray{
        h1{
            color: #6D6E71;
        }
        .ribbon{
            height: 25px;
            background: #548ab6; /* Old browsers */
            background: -moz-linear-gradient(20deg, #6D6E71 0%, #6D6E71 22%, #6D6E71 22%, #939598 22%, #939598 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(20deg, #6D6E71 0%,#6D6E71 22%,#6D6E71 22%,#939598 22%,#939598 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(20deg, #6D6E71 0%,#6D6E71 22%,#6D6E71 22%,#939598 22%,#939598 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6D6E71', endColorstr='#939598',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
        }
    }
    
    .project,.partner{
        margin-top: 30px;
        display: block; 
        h2, h3{
            font-size: 14px; text-align: center;
            font-family: 'HelveticaNC';
            font-weight: bold;
            color: #626262;
            margin-top: 10px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
        h3{
            margin-top: 5px;
        }
        
        &:hover{
            text-decoration: none;
        }
    }
    
    .partner{
        margin-top: 0px;
        margin-bottom: 20px;
    }
    
    .partners-title{
            font-size: 25px; text-align: center;
            font-family: 'HelveticaNC';
            font-weight: bold;
            margin-bottom: -15px; 
            margin-top: 80px;
            text-align: center;
    }
    
    
    
    
    
    
    
}