/* This stylesheet generated by Transfonter (https://transfonter.org) on April 7, 2017 10:40 AM */

@font-face {
	font-family: 'HelveticaNC';
	src: url('webfonts/webfonts/HelveticaNeueLTStd-BdCn.eot');
	src: url('webfonts/webfonts/HelveticaNeueLTStd-BdCn.eot?#iefix') format('embedded-opentype'),
		url('webfonts/HelveticaNeueLTStd-BdCn.woff') format('woff'),
		url('webfonts/HelveticaNeueLTStd-BdCn.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
} 

@font-face {
	font-family: 'HelveticaNC'; 
	src: url('webfonts/HelveticaNeueLTStd-Cn.eot');
	src: url('webfonts/HelveticaNeueLTStd-Cn.eot?#iefix') format('embedded-opentype'),
		url('webfonts/HelveticaNeueLTStd-Cn.woff') format('woff'),
		url('webfonts/HelveticaNeueLTStd-Cn.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaN';
	src: url('webfonts/HelveticaNeueLTStd-Bd.eot');
	src: url('webfonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'),
		url('webfonts/HelveticaNeueLTStd-Bd.woff') format('woff'),
		url('webfonts/HelveticaNeueLTStd-Bd.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'HelveticaN';
	src: url('webfonts/HelveticaNeueLTStd-Roman.eot');
	src: url('webfonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
		url('webfonts/HelveticaNeueLTStd-Roman.woff') format('woff'),
		url('webfonts/HelveticaNeueLTStd-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


$font-family-serif:       'HelveticaN', serif !default;

@import "bootstrap";
@import "header";
@import "slider";
@import "footer";
@import "scr";
@import "news";
@import "project";

body{
    @media(max-width: 991px){
        font-size: 18px;
    }
    @media(max-width: 767px){
        font-size: 16px;
    }
}

img{
    max-width: 100%
}

a:focus{
    outline: none;
    border: none;
}

a, span{
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -ms-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.padding{
    padding: 20px 70px;
    padding-bottom: 150px;
    
    @media(max-width: 991px){
        padding: 20px 20px;
        padding-bottom: 50px;
    }
    @media(max-width: 768px){
        padding: 20px 10px;
    }
    @media(max-width: 480px){
        padding: 20px 0px;
    }
    
}

@media(max-width: 1199px){
    .no-padding{
        padding:0;
      margin:0;
    }
    
}


.page{
    h1{
        font-size: 36px;
        @media(max-width: 991px){
            font-size: 33px;
            margin-bottom: 10px;
        }
        @media(max-width: 767px){
            font-size: 30px;
            margin-bottom: 10px;
        }
        
        font-family: 'HelveticaNC';
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 0;
    }
    
    h2{
        font-size: 29px;
        margin-bottom: 20px;
        @media(max-width: 991px){
            font-size: 25px;
        }
        @media(max-width: 767px){
            font-size: 22px;
        }
    }
    
    .team{
        
        .col-sm-4{
            @media(max-width: 767px){
                flex-basis: 50%;
                max-width: 50%;
            }
            @media(max-width: 575px){
                flex-basis: 100%;
                max-width: 100%;
            }

            .name{
                position: absolute;
                bottom: 0;
                width: 100%;
                padding: 5px 0; 
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 20px;
                margin-top: 10px;
                left: 0;
            }
        }
        .person{
            flex-basis: 100%;
            max-width: 100%;
            height: 380px;
            color: #666;
            position: relative;
            background-size: cover;
            background-position: 50% 50%;
            margin-bottom: 50px;
            .overlay{
                position: absolute;
                height: 100%;
                width: 100%;
                padding: 20px 20px 30px;
                z-index: 1;
               opacity: 0;
               visibility: hidden;
               -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -ms-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
                background: rgba(228, 228, 228, 0.89);
                padding-bottom: 20px;
                
                .text{
                     overflow-y: auto;
                        overflow-y: auto;
                       position: absolute;
                       top: 20px;
                       left: 20px;
                       width: calc(100% - 40px);
                       height: calc(100% - 60px);
                }
                
            }
            
            &:hover, &:focus{
                .overlay{
                     opacity: 1;
                        visibility: visible;
                }
               
            }
            
            .ribbon{
                z-index: 100;;
                height: 25px;
                position: absolute; 
                bottom: 0;
                left: 0;
                width: 100%;
                background: #548ab6; /* Old browsers */
                background: -moz-linear-gradient(20deg, #548ab6 0%, #548ab6 22%, #548ab6 22%, #58b1dd 22%, #58b1dd 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(20deg, #548ab6 0%,#548ab6 22%,#548ab6 22%,#58b1dd 22%,#58b1dd 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(20deg, #548ab6 0%,#548ab6 22%,#548ab6 22%,#58b1dd 22%,#58b1dd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#548ab6', endColorstr='#58b1dd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                
                
                
                &.color1{
                    background: #548ab6; /* Old browsers */
                    background: -moz-linear-gradient(20deg, #8b568a 0%, #8b568a 22%, #8b568a 22%, #bc5aa2 22%, #bc5aa2 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(20deg, #8b568a 0%,#8b568a 22%,#8b568a 22%,#bc5aa2 22%,#bc5aa2 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(20deg, #8b568a 0%,#8b568a 22%,#8b568a 22%,#bc5aa2 22%,#bc5aa2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8b568a', endColorstr='#bc5aa2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                }
                &.color2{
                    background: #548ab6; /* Old browsers */
                    background: -moz-linear-gradient(20deg, #F09D61 0%, #F09D61 22%, #F09D61 22%, #F3B466 22%, #F3B466 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(20deg, #F09D61 0%,#F09D61 22%,#F09D61 22%,#F3B466 22%,#F3B466 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(20deg, #F09D61 0%,#F09D61 22%,#F09D61 22%,#F3B466 22%,#F3B466 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F09D61', endColorstr='#F3B466',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                }
                &.color3{
                    background: #548ab6; /* Old browsers */
                    background: -moz-linear-gradient(20deg, #68805E 0%, #68805E 22%, #68805E 22%, #79A869 22%, #79A869 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(20deg, #68805E 0%,#68805E 22%,#68805E 22%,#79A869 22%,#79A869 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(20deg, #68805E 0%,#68805E 22%,#68805E 22%,#79A869 22%,#79A869 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#68805E', endColorstr='#79A869',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                }
                &.color4{
                    background: #548ab6; /* Old browsers */
            background: -moz-linear-gradient(20deg, #6D6E71 0%, #6D6E71 22%, #6D6E71 22%, #939598 22%, #939598 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(20deg, #6D6E71 0%,#6D6E71 22%,#6D6E71 22%,#939598 22%,#939598 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(20deg, #6D6E71 0%,#6D6E71 22%,#6D6E71 22%,#939598 22%,#939598 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6D6E71', endColorstr='#939598',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
                }
                
            }
            h2{
                margin: 0;
                margin-top: 0;
                font-size: 14px; 
                line-height: 17px;
                font-family: 'HelveticaNC';
                font-weight: bold;
                text-transform: uppercase;
            }
            h3{
                margin: 0;
                font-size: 13px; 
                line-height: 17px;
            }
        }
    }
    .pagination{
        font-size: 22px;
        font-weight: bold; 
        @media(max-width: 991px){
            font-size: 20px;
        }
        @media(max-width: 767px){
            font-size: 18px;
        }
        strong{
            color: #c40050;
            margin: 0 3px;
        }
        a{
            margin: 0 3px;
            color: #626262;
            &:hover{
                text-decoration: none; 
                color: #c40050;
            }
        }
    }
    
    .contact-item{
        font-size: 18px; 
        @media(max-width: 991px){
            font-size: 17px;
        }
        @media(max-width: 767px){
            font-size: 16px;
            margin-bottom: 30px;
        }
        margin-top: 20px;
        .gmap iframe{
            margin-top: 20px;
            width: 100%!important; 
            height: 200px!important; 
        }
    }
    
    .terms{
        padding: 30px 0; 
        border-top: 2px solid #868585;
        margin-top: 60px;
        font-size: 14px;
        a{
            font-weight: bold; 
            color: #626262;
            
            &:hover{
                text-decoration: none;
                color: #F99B1D;
            }
        }
    }
}



