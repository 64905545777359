footer{
    
    .inner{
        background: url('/img/footer-shapes.png') right bottom; 
        background-repeat: no-repeat;
        padding: 20px 0;
       
        @media(max-width: 767px){
            padding-bottom: 95px
        }
        .footer-text{
            margin: 20px;
            float: left;
            @media(max-width: 991px){
                margin: 20px 0;
            }
            
        }
        p{
            font-size: 11px;
            &.copyright{
                font-family: 'HelveticaNC';
                font-weight: bold; font-size: 9px;
                margin-bottom: 5px;
                text-transform: uppercase;
                font-size: 12px;
            }
        }

        .social{
            margin: 20px;
            float: left;
            @media(max-width: 991px){
                margin: 10px 0;
                display: block;
                float: none;
            }
            li{
                display: inline-block;
                a{
                    color: #6f6f6f;
                    font-size: 14px;
                    display: block; 
                    margin-left: 5px;
                }
                &:hover{
                    a{
                        color: #cc713c;
                        text-decoration: none;
                    }
                 }
            }
        }
    }
    
    
        
}