article.single-post{
    margin-bottom: 40px;
    padding-bottom: 15px;
    border-bottom: 2px solid #868585;
    
    .thumb{
       width: 100%; 
       img{
           width: 100%;
       }
    }
    
    
    
    .date{
        font-size: 24px;
        @media(max-width: 991px){
            font-size: 20px;
        }
        @media(max-width: 767px){
            font-size: 18px;
        }
    }
    
    a{
        text-decoration: none; 
        color: #626262;
        &:hover{
            text-decoration: none; 
            color: #F99B1D;
        }
    }
    
    h2{
        font-size: 36px; 
        font-family: 'HelveticaNC';
        font-weight: bold; 
        text-transform: uppercase;
        margin-top: 5px;
        @media(max-width: 991px){
            font-size: 32px;
            margin-bottom: 10px;
        }
        @media(max-width: 767px){
            font-size: 28px;
            margin-bottom: 10px;
        }
    }
    
    .read-more{
        font-weight: bold; font-size: 17px;
        @media(max-width: 991px){
            font-size: 16px;
        }
        @media(max-width: 767px){
            font-size: 15px;
        }
    }
}

.news-image{
        background-size: cover;
        background-position: 50% 50%;
        height: 550px;
        @media(max-width: 991px){
            height: 450px;
        }
        @media(max-width: 767px){
           height: 350px;
        }
    }
.news-info{
    .news-text{
        padding-bottom: 20px;
        border-bottom: 2px solid #868585;
    }
    h2{
        font-size: 29px;
        @media(max-width: 991px){
            font-size: 26px;
        }
        @media(max-width: 767px){
            font-size: 23px;
        }
    }
    h1{
        margin-top: 5px;
        
    }
    .read-more{
        font-weight: bold; 
        font-size: 22px;
        margin-top: 20px;
        display: block;
        color: #626262;
        &:hover{
            text-decoration: none; 
            color: #F99B1D;
        }
        @media(max-width: 991px){
            font-size: 19px;
        }
        @media(max-width: 767px){
            font-size: 17px;
        }
        
    }
    .date{
        font-size: 23px;
        @media(max-width: 991px){
            font-size: 20px;
        }
        @media(max-width: 767px){
            font-size: 18px;
        }
    }
}

