.owl-carousel .single{ display: none; }

#carousel{
    
    

    &:before{
        content: "";
        display: block; 
        position: absolute; 
        background-image: url('/img/slider-top.png');
        background-position: 0% 0%; 
        background-repeat: no-repeat;
        width: 100%;
        height: 93px;
        @media(max-width: 767px){ 
            height: 72px;
            background-size: cover;
        }
        z-index: 100;
    }

    &:after{
        content: "";
        position: absolute;
        background-image: url('/img/slider-bottom.png');
        background-position: 100% 100%; 
        background-repeat: no-repeat;
        width: 100%;
        height: 123px;
        display: block;    
        z-index: 100; 
        @media(max-width: 767px){ 
            height: 100px;
            background-size: cover;
        }
        bottom: 0;
    }

    .owl-controls{
            position: absolute;
            z-index: 101;
            right: 0;
            bottom: 0;
            margin-top: 0;
            padding: 50px 20px;
            @media(max-width: 767px){
                padding: 35px 16px;
            }
            margin-right: 28px;
        
        
        .owl-nav{
            .owl-prev{
                position: absolute;
                left: 0; 
            }
            .owl-next{
                position: absolute;
                right: 0;
            }
        }
        
        .owl-dots{
            padding: 4px;
        }
        
    }
    
    .item{
         height: 550px;
         @media(max-width: 991px){
            height: 450px;
        }
        @media(max-width: 767px){
            height: 350px;
        }
    }



    /*$deg: 5deg;

    

    .skew
    {
        position: relative;
        overflow: hidden;
    }

    .skew::after
    {
        content: "";
        position: absolute;
        width: 200%;
        height: 20%;
        top: 90%;
        left: -50%;
        z-index: 1000;
        background: #FFF;
        -webkit-transform: skewY(-$deg);
        -moz-transform: skewY(-$deg);
        -ms-transform: skewY(-$deg);
        -o-transform: skewY(-$deg);
        transform: skewY(-$deg);
    }
    .skew::before
    {
        content: "";
        position: absolute;
        width: 200%;
        height: 47px;
        top: 0;
        left: -50%;
        z-index: 1000;
        background: #FFF;
        -webkit-transform: skewY(-$deg);
        -moz-transform: skewY(-$deg); 
        -ms-transform: skewY(-$deg);
        -o-transform: skewY(-$deg);
        transform: skewY(-$deg);
    }
    */



}

.owl-theme .owl-dots,.owl-theme .owl-nav{
    text-align:center;
    -webkit-tap-highlight-color:transparent
}

.owl-theme .owl-nav.disabled+.owl-dots{
    margin-top:10px
}
.owl-theme .owl-dots .owl-dot{
    display:inline-block;zoom:1
}
.owl-theme .owl-dots .owl-dot span{
    width:10px;
    height:10px;
    margin:5px 7px;
    background:#FFF;
    display:block;
    -webkit-backface-visibility:visible;
    transition:opacity .2s ease;
    border-radius:30px
}
.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{
    background:#57575b
}
